import { Component } from '@angular/core';
import { ProjectDeliverableTitleGridCell } from '../../models/project-deliverable-title-grid-cell.model';
import { WorkplanRoutes } from 'src/app/roles/da-engineer/workplan/workplan.constants';

@Component({
  selector: 'app-project-deliverable-table-renderer',
  templateUrl: './project-deliverable-table-renderer.component.html',
  styleUrls: ['./project-deliverable-table-renderer.component.scss'],
})
export class ProjectDeliverableTableRendererComponent {
  readonly WorkplanRoutes = WorkplanRoutes;
  projectDeliverable?: ProjectDeliverableTitleGridCell;

  agInit(params: { value: ProjectDeliverableTitleGridCell }) {
    this.projectDeliverable = params.value;
  }
}
