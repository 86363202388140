import { routerNavigatedAction } from '@ngrx/router-store';
import { createReducer, on } from '@ngrx/store';
import {
  showAuthorizationErrorPage,
  loadingFinished,
  loadingStarted,
  killLoadingSpinner,
  setApplicationVersion,
  cacheClearFlag,
} from './shared.actions';

export interface LoadState {
  loadingSemaphore: number;
  showSpinner: boolean;
}

export interface ApplicationVersion {
  currentVersion: string | null;
}

export interface CacheClearConfig {
  urlBeforeClear: string | null;
  cacheClearFlag: boolean;
}

export interface AuthState {
  showAuthErrorPage: boolean;
  errorStatus: number;
  errorMessage: string;
}

const initialLoadState: LoadState = {
  loadingSemaphore: 0,
  showSpinner: false,
};

export const initialApplicationVersionState: ApplicationVersion = {
  currentVersion: null,
};

export const initialCacheClearConfigState: CacheClearConfig = {
  urlBeforeClear: null,
  cacheClearFlag: false,
};

const initialAuthState: AuthState = {
  showAuthErrorPage: false,
  errorStatus: 0,
  errorMessage: '',
};

export const loadReducer = createReducer(
  initialLoadState,
  on(loadingStarted, (state) => ({
    ...state,
    loadingSemaphore: state.loadingSemaphore + 1,
    showSpinner: true,
  })),
  on(loadingFinished, (state) => {
    const loadingSemaphore =
      state.loadingSemaphore > 0
        ? state.loadingSemaphore - 1
        : state.loadingSemaphore;

    return {
      ...state,
      loadingSemaphore: loadingSemaphore,
      showSpinner: loadingSemaphore > 0,
    };
  }),
  on(killLoadingSpinner, (state) => ({
    ...state,
    loadingSemaphore: initialLoadState.loadingSemaphore,
    showSpinner: initialLoadState.showSpinner,
  }))
);

export const applicationVersionReducer = createReducer(
  initialApplicationVersionState,
  on(setApplicationVersion, (state, { currentVersion }) => ({
    ...state,
    currentVersion,
  }))
);

export const cacheClearConfigReducer = createReducer(
  initialCacheClearConfigState,
  on(routerNavigatedAction, (state, { payload }) => ({
    ...state,
    urlBeforeClear: getCacheClearUrl(
      payload.routerState.url,
      state.urlBeforeClear
    ),
  })),
  on(cacheClearFlag, (state, { cacheClearFlag }) => ({
    ...state,
    cacheClearFlag,
  }))
);

export const authReducer = createReducer(
  initialAuthState,
  on(showAuthorizationErrorPage, (state, { error, errorMessage }) => ({
    ...state,
    showAuthErrorPage: true,
    errorStatus: error?.status,
    errorMessage: errorMessage,
  })),
  on(routerNavigatedAction, (state) => ({
    ...state,
    showAuthErrorPage: initialAuthState.showAuthErrorPage,
    errorStatus: initialAuthState.errorStatus,
    errorMessage: initialAuthState.errorMessage,
  }))
);

function getCacheClearUrl(currentUrl: string, previousUrl: string | null) {
  if (currentUrl === '/clear' || currentUrl === '/') {
    return previousUrl;
  }

  return currentUrl;
}
