import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { AppState } from 'src/app/app.state';
import { AgGridIconButtonRendererModel } from 'src/app/shared/models/ag-grid-icon-button-renderer.model';
import { DADeliverableWithMappingsViewModel } from 'src/app/shared/models/autogenerated';
import { nameof } from 'src/app/shared/utils/nameof';
import { DeliverableManagerGridCellActionsModel } from '../../../../../shared/models/deliverable-manager-grid-cell-actions.model';
import {
  DELIVERABLE_MANAGER_TABLE_DA_CONFIG_STORE,
  DELIVERABLE_MANAGER_TABLE_PROJECT_CONFIG_STORE,
} from '../../screening.constants';
import { deleteMappingsForDaDeliverable } from '../../store/screening.actions';
import { DeliverableManagerCommonTableComponent } from '../deliverable-manager-common-table/deliverable-manager-common-table.component';
import { ExportableColDefWithTitle } from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.reducer';

@Component({
  selector: 'app-deliverable-manager-da-deliverable-table',
  templateUrl:
    '../deliverable-manager-common-table/deliverable-manager-common-table.component.html',
  styleUrls: [
    '../deliverable-manager-common-table/deliverable-manager-common-table.component.scss',
  ],
})
export class DeliverableManagerDADeliverableTableComponent extends DeliverableManagerCommonTableComponent<DADeliverableWithMappingsViewModel> {
  configStoreTableName: string = DELIVERABLE_MANAGER_TABLE_DA_CONFIG_STORE;

  columnDefs: ExportableColDefWithTitle[] = [
    {
      field: nameof<DADeliverableWithMappingsViewModel>('deliverableId'),
      headerName: 'ID',
      width: 80,
      type: 'rightAligned',
      hide: true,
    },
    {
      field: nameof<DADeliverableWithMappingsViewModel>('drsCode'),
      headerName: 'DRS Code',
      width: 130,
      hide: true,
    },
    {
      field: nameof<DADeliverableWithMappingsViewModel>(
        'deliverableDiscipline'
      ),
      headerName: 'Discipline',
      resizable: true,
      width: 100,
    },
    {
      field: nameof<DADeliverableWithMappingsViewModel>('docType'),
      headerName: 'Type',
      resizable: true,
      width: 80,
    },
    {
      field: nameof<DADeliverableWithMappingsViewModel>('title'),
      headerName: 'Title',
      resizable: true,
      tooltipField: nameof<DADeliverableWithMappingsViewModel>('title'),
    },
    {
      ...this.tagsColumn,
      cellRendererParams: {
        onInit: (data: any, actions: any) => {
          this.titleToTagsActions = actions;
          this.setTagsToTitleActions(
            'Set deliverable title as tags in Project filter',
            (data: any) => {
              this.sendTagsToFilter(
                this.store,
                DELIVERABLE_MANAGER_TABLE_PROJECT_CONFIG_STORE,
                data.title
              );
            }
          );
        },
      } as AgGridIconButtonRendererModel,
      ignoreExport: true,
    },
    {
      headerName: 'Map',
      colId: 'map',
      minWidth: 90,
      maxWidth: 90,
      pinned: 'right',
      filter: 'number',
      valueGetter: (params) =>
        params.data.projectDeliverableIdList?.length ?? 0,
      type: 'centerAligned',
      cellRenderer: 'deliverableManagerMappingRenderer',
      cellRendererParams: {
        onMappingClick: (data: DADeliverableWithMappingsViewModel) =>
          this.setFilterByDeliverableIds.emit(data.projectDeliverableIdList),
        onDeleteClick: (daDeliverableId) =>
          this.deleteConfirmationDialog(this.store, () =>
            this.deleteMappings(daDeliverableId)
          ),
      } as DeliverableManagerGridCellActionsModel,
    },
    {
      checkboxSelection: true,
      resizable: false,
      minWidth: 50,
      maxWidth: 50,
      type: 'centerAligned',
      pinned: 'right',
    },
  ];

  constructor(private store: Store<AppState>) {
    super();
  }

  private deleteMappings(daDeliverableId: string) {
    this.deselectAllInBothTables.emit();
    this.store.dispatch(
      deleteMappingsForDaDeliverable({
        daDeliverableId,
      })
    );
  }
}
