<span
  class="project-deliverable-title"
  *ngIf="!projectDeliverable?.displayAsLinkToOtt; else titleLink"
>
  {{ projectDeliverable?.projectDeliverableTitle }}
</span>
<ng-template #titleLink>
  <a
    [routerLink]="[
      '../..',
      WorkplanRoutes.OBSERVATION_TOOL,
      projectDeliverable?.observationToolId
    ]"
    class="adap-link-blue-light project-deliverable-title"
  >
    {{ projectDeliverable?.projectDeliverableTitle }}
  </a>
</ng-template>
<app-project-deliverable-carryover-label
  *ngIf="projectDeliverable?.isCarryOver"
  [isShortForm]="true"
></app-project-deliverable-carryover-label>
