import { Action, ActionCreator, createAction, props } from '@ngrx/store';
import {
  FileParameter,
  ProjectDeliverableImportResultViewModel,
  ProjectDeliverableViewModel,
} from 'src/app/shared/models/autogenerated';

export const deleteProjectDeliverable = createAction(
  '[ProjectDeliverables] Delete Project Deliverable',
  props<{ projectDeliverableId: string }>()
);

export const downloadProjectDeliverablesImportTemplate = createAction(
  '[ProjectDeliverables] Download the import template file'
);

export const editProjectDeliverable = createAction(
  '[ProjectDeliverables] Edit Project Deliverable',
  props<{ projectDeliverable: ProjectDeliverableViewModel }>()
);

export const loadProjectDeliverables = createAction(
  '[ProjectDeliverables] Load ProjectDeliverables'
);

export const clearProjectDeliverableForm = createAction(
  '[ProjectDeliverables] Clear Project Deliverable Form'
);

export const createProjectDeliverable = createAction(
  '[ProjectDeliverables] Create Project Deliverable'
);

export const loadSMEDisciplineConfigCodes = createAction(
  '[ProjectDeliverables] Load SME Discipline Config Codes'
);

export const loadSMEDisciplineConfigCodesSuccess = createAction(
  '[ProjectDeliverables] Load SME Discipline Config Codes Success',
  props<{ codes: string[] | null }>()
);

export const updateProjectDeliverable = createAction(
  '[ProjectDeliverables] Update Project Deliverable'
);

export const setProjectDeliverables = createAction(
  '[ProjectDeliverables] Load ProjectDeliverables Success',
  props<{
    projectDeliverables: ProjectDeliverableViewModel[];
  }>()
);

export const updateProjectDeliverableSuccess = createAction(
  '[ProjectDeliverables] Update Project Deliverable Success',
  props<{ project: ProjectDeliverableViewModel | null }>()
);

export const deleteProjectDeliverableSuccess = createAction(
  '[ProjectDeliverables] Delete Project Deliverable Success'
);

export const createProjectDeliverableSuccess = createAction(
  '[ProjectDeliverables] Create Project Deliverable Success',
  props<{ projectDeliverable: ProjectDeliverableViewModel | null }>()
);

export const importProjectDeliverables = createAction(
  '[ProjectDeliverables] Import',
  props<{
    file: FileParameter;
    onlyAdd: boolean;
    insertMissing: boolean;
    removeDeleted: boolean;
    updateExisting: boolean;
    onSuccessStoreAction: ActionCreator<string, () => Action<string>>;
  }>()
);

export const importProjectDeliverablesSuccess = createAction(
  '[ProjectDeliverables] Import Project Deliverables Success',
  props<{
    projectDeliverables: ProjectDeliverableImportResultViewModel | null;
  }>()
);

export const checkForProjectDeliverablesImportDuplicates = createAction(
  '[ProjectDeliverables] Check for Project Deliverables Import Duplicates',
  props<{
    file: FileParameter;
    onSuccessStoreAction: ActionCreator<string, () => Action<string>>;
  }>()
);

export const projectDeliverablesImportDuplicatesSuccess = createAction(
  '[ProjectDeliverables] Open Project Deliverables Import Duplicates Success'
);
