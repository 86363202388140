import { Directive, forwardRef } from '@angular/core';
import { FormViewAdapter, NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'quill-editor[ngrxFormControlState]',
  providers: [
    {
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => NgrxQuillValueAccessorDirective),
      multi: true,
    },
  ],
})
export class NgrxQuillValueAccessorDirective implements FormViewAdapter {
  private subscriptions: Subscription[] = [];

  constructor(private quillEditor: QuillEditorComponent) {}
  
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  writeValue(value: any): void {
    this.quillEditor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.quillEditor.onContentChanged.subscribe((event: any) => {
      fn(event.html);
    });
  }
  setViewValue(value: any): void {
    this.writeValue(value);
  }

  setOnChangeCallback(): void { }
  setOnTouchedCallback(fn: any): void {    
    this.quillEditor.registerOnTouched(fn);
    this.quillEditor.onBlur.subscribe(() => {      
      fn();
    });
  }

  setDisabledState?(isDisabled: boolean): void {
    this.quillEditor.setDisabledState(isDisabled);
  }
}