import Quill from 'quill';

export function getPlainTextLength(html: string | null): number {
  if (!html) {
    return 0;
  }
  
  const div = document.createElement('div');
  div.innerHTML = html;

  // Count <p></p> tags
  const pCount = (html.match(/<p><\/p>/gi) || []).length;

  // Get the plain text length
  const text = div.innerText ?? '';
  if(text.length === 0 && pCount == 1){
    return 0;
  }

  return text.length + pCount;
}

export function getPlainText(html: string | null): string {
  if (!html) {
    return '';
  }
  
  const div = document.createElement('div');
  div.innerHTML = html;

  // Get the plain text
  const text = div.innerText ?? '';

  return text;
}

export function convertPlainTextToRichText(content: string): string {
  return content.replace(/\n/g, '<br>');
}

export function convertAndPastePlainTextToRichText(editor: Quill, content: string) {
  const isRichText = /<\/?[a-z][\s\S]*>/i.test(content);
  if (!isRichText){
    const convertedData = convertPlainTextToRichText(content);
    if (convertedData !== content) {
      editor.clipboard.dangerouslyPasteHTML(convertedData, 'silent');
    }
  }
}