import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import Quill from 'quill';
import { convertAndPastePlainTextToRichText, getPlainTextLength } from '../../utils/rich-text-editor-util';

const FontAttributor = Quill.import('attributors/class/font')as any;
FontAttributor.whitelist = [
  'GothamNarrowBook',
  'sansserif',
  'roboto',
  'cursive',
  'fantasy',
  'monospace'
];
const SizeStyle = Quill.import('attributors/style/size') as any;
SizeStyle.whitelist = [ '0.75em', '1em', '1.5em', '2.5em' ];
Quill.register(SizeStyle, true);
Quill.register(FontAttributor, true);

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  host: {
    class: 'rich-text-editor',
  },
})
export class RichTextEditorComponent{
  @Input() id!: string;
  @Input() name!: string;
  @Input() formControlState!: FormControlState<any>;
  @Input() counter: number | undefined;
  @Output() counterChange = new EventEmitter<number>();
  oldValueConverted: boolean = false;

  public editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      ['blockquote', 'code-block'],
      [ {size: [ '0.75em', '1em', '1.5em', '2.5em' ]}],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ["link"],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      ['clean'],
      [{ font: ['GothamNarrowBook', 'sansserif', 'roboto', 'cursive', 'fantasy', 'monospace'] }],
    ],
  };  

  onEditorCreated(editor: Quill) {
    this.oldValueConverted = false;
    this.counter = this.calculateLength(editor);
    this.onCounterChange(this.counter);
    
    editor.on('editor-change', () => {
      this.onEditorChange(editor);
    });
  }

  onEditorChange(editor: Quill){
    if (!this.oldValueConverted && this.formControlState?.value && this.formControlState?.isEnabled) {
      this.oldValueConverted = true;        

      convertAndPastePlainTextToRichText(editor, this.formControlState.value);
    }
    this.counter = this.calculateLength(editor);
    this.onCounterChange(this.counter);
  }

  calculateLength(editor: Quill) {
    if(editor){
      return getPlainTextLength(editor.getSemanticHTML());
    }

    return 0;
  }

  onCounterChange(newCounter: number) {
    this.counter = newCounter;
    this.counterChange.emit(this.counter);
  }
}