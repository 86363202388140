import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { map, catchError } from 'rxjs/operators';
import {
  SMEDisciplineAssignmentPeerPoolViewModel,
  SMEDisciplineAssignments,
} from '../models/autogenerated';

@Pipe({
  name: 'AssignmentSMEUserOidToNames',
})
export class AssignmentSMEUserOidToNamesPipe implements PipeTransform {
  constructor(private userDataService: UserDataService) {}

  transform(
    assignments: SMEDisciplineAssignmentPeerPoolViewModel[]
  ): Observable<string> {
    if (!assignments || assignments.length === 0) {
      return of('');
    }

    const filteredAssignments = assignments.filter(
      (assignment) =>
        assignment.label === SMEDisciplineAssignments.PrimarySME ||
        assignment.label === SMEDisciplineAssignments.SecondarySME
    );

    const userIds = filteredAssignments.map((assignment) => assignment.userId);
    const userNames$ = userIds.map((userId) =>
      this.userDataService
        .getUserNameByOid(userId)
        .pipe(catchError(() => of('')))
    );

    // Join all user names into a single string
    return forkJoin(userNames$).pipe(
      map((userNames) =>
        userNames
          .filter((name) => name && !/^[0-9a-fA-F-]{36}$/.test(name))
          .join('; ')
      )
    );
  }
}
