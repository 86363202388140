<app-input-container
  class="editable-text-container"
  [label]="editableTextLabel"
  [isRequired]="isRequired"
  [characterCounterLength]="counter ?? 0"
  [characterCounterMaxLength]="editableTextMaxLength"
  [app-input-validation-container]="editableTextFormControl"
>
  <rich-text-editor
    [formControlState]="editableTextFormControl!"
    [id]="id!"
    [name]="name!"
    [counter]="counter"
    (counterChange)="updateCounter($event)"
    ></rich-text-editor>

  <button
    *ngIf="showCopyLabel"
    type="button"
    (click)="copyEvent.emit()"
    class="adap-btn-blank copy-button"
    [disabled]="editableTextFormControl?.isDisabled"
  >
    {{ copyLabel }}
    <mat-icon class="copy-icon">content_copy</mat-icon>
  </button>
</app-input-container>
