import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CacheClearComponent } from './core/components/cache-clear/cache-clear.component';
import { RoleGuard } from './core/guards/role.guard';
import {
  DA_ENGINEER_ROUTE,
  GLOBAL_METRICS_ROUTE,
  GLOBAL_SETTINGS_ROUTE,
  PROJECT_CONTACT_ROUTE,
  SME_ROUTE,
} from './roles/_shared-by-role/project-dashboard/project.constants';
import { UserRoles } from './shared/utils/constants';

const canActivateWithRoleGuard = [MsalGuard, RoleGuard];

const routes: Routes = [
  {
    canActivate: [MsalGuard],
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('src/app/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    redirectTo: 'project',
  },
  {
    data: {
      roles: [UserRoles.CHAPTER_MANAGER],
    },
    canActivate: canActivateWithRoleGuard,
    path: 'chapter-manager',
    loadChildren: () =>
      import('src/app/roles/chapter-manager/chapter-manager.module').then(
        (m) => m.ChapterManagerModule
      ),
  },
  {
    data: {
      roles: [UserRoles.SME],
    },
    canActivate: canActivateWithRoleGuard,
    path: SME_ROUTE,
    loadChildren: () =>
      import('src/app/roles/sme/sme.module').then((m) => m.SMEModule),
  },
  {
    data: {
      roles: [UserRoles.DA_ENGINEER],
    },
    canActivate: canActivateWithRoleGuard,
    path: DA_ENGINEER_ROUTE,
    loadChildren: () =>
      import('src/app/roles/da-engineer/da-engineer.module').then(
        (m) => m.DAEngineerModule
      ),
  },
  {
    data: {
      roles: [UserRoles.PROJECT_CONTACT],
    },
    canActivate: canActivateWithRoleGuard,
    path: PROJECT_CONTACT_ROUTE,
    loadChildren: () =>
      import('src/app/roles/project-contact/project-contact.module').then(
        (m) => m.ProjectContactModule
      ),
  },
  {
    path: GLOBAL_SETTINGS_ROUTE,
    loadChildren: () =>
      import(
        'src/app/roles/_shared-by-role/global-settings/global-settings.module'
      ).then((m) => m.GlobalSettingsModule),
  },
  {
    data: {
      roles: [UserRoles.DA_ENGINEER],
    },
    canActivate: canActivateWithRoleGuard,
    path: GLOBAL_METRICS_ROUTE,
    loadChildren: () =>
      import(
        'src/app/roles/da-engineer/global-metrics/global-metrics.module'
      ).then((m) => m.GlobalMetricsDAEngineerModule),
  },
  {
    canActivate: [MsalGuard],
    path: 'clear',
    component: CacheClearComponent,
  },
  {
    //ensure that all paths are captured and redirected to a route that is guarded with MSAL
    path: '**',
    redirectTo: '',
  },
];
const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //make sure that the router blocks the bootstrapping and resolves
      // the routes+guards first, to avoid split-second flash of UI
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled', // Remove this line to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
