import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MY_PROJECTS_ROUTE } from 'src/app/roles/_shared-by-role/project-dashboard/project.constants';
import { selectCurrentPath } from 'src/app/shared/store/shared.selectors';
import { ProjectPageViewState, ProjectState } from './project.reducer';

export const selectProjectFeature =
  createFeatureSelector<ProjectState>('project');

export const selectProjectPageViewFeature =
  createFeatureSelector<ProjectPageViewState>('projectPageView');

export const selectAllProjects = createSelector(
  selectProjectFeature,
  selectCurrentPath,
  (state, currentPath: string) => {
    if (currentPath.includes(MY_PROJECTS_ROUTE)) {
      return state.projectsByUser;
    }

    return state.allProjects;
  }
);

export const selectCurrentProject = createSelector(
  selectProjectFeature,
  (state) => state.currentProject
);

export const selectEditProjectForm = createSelector(
  selectProjectFeature,
  (state) => state.editProjectForm
);

export const selectIsCurrentProjectUpdated = createSelector(
  selectProjectFeature,
  (state) => state.isProjectChanged
);

export const selectCurrentPhaseEditForm = createSelector(
  selectProjectFeature,
  (state) => state.currentPhaseForm
);

export const selectCurrentPhase = createSelector(
  selectProjectFeature,
  (state) => state.currentProject?.currentPhase
);

export const selectDisciplinesForCurrentPhase = createSelector(
  selectProjectFeature,
  (state) => state.disciplines
);

export const selectProjectPhases = createSelector(
  selectProjectFeature,
  (state) => state.currentProject?.phases
);

export const selectProjectPageView = createSelector(
  selectProjectPageViewFeature,
  (state) => state.projectPageView
);

export const selectIPIMSDomains = createSelector(
  selectProjectFeature,
  (state) => state.domains
);

export const selectIPIMSDomain = createSelector(
  selectProjectFeature,
  (state) => state.currentProject?.currentPhase?.ipimsDomain
);

export const selectProjectValueRecognitionForm = createSelector(
  selectProjectFeature,
  (state) => state.projectValueRecognitionDetailsForm
);

export const selectProjectValueRecognition = createSelector(
  selectProjectFeature,
  (state) => state.projectValueRecognitionDetails
);
