<ul class="tabs">
  <li class="tab" *ngFor="let tab of tabs">
    <ng-container *ngIf="!tab.disabled && !displayAsButton">
        <a
          class="tab-link"
          [routerLink]="tab.routerLink"
          routerLinkActive="active"
          (click)="tab.buttonTabOnClick && tab.buttonTabOnClick()"
        >
          <span class="tab-link-content">{{ tab.title }}</span>
        </a>
        <ng-container
            *ngIf="tab.templateRef !== undefined"
            [ngTemplateOutlet]="tab.templateRef"
            [ngTemplateOutletContext]="{ data: tab.templateData }"
        ></ng-container>
    </ng-container>
    <ng-container *ngIf="!tab.disabled && displayAsButton">
      <button
        class="adap-btn-outline tab-link"
        [class.active]="tab.isButtonTabActive"
        type="button"
        (click)="tab.buttonTabOnClick && tab.buttonTabOnClick()"
      >
        {{ tab.title }}
        <ng-container
          *ngIf="tab.templateRef !== undefined"
          [ngTemplateOutlet]="tab.templateRef"
          [ngTemplateOutletContext]="{ data: tab.templateData }"
        ></ng-container>
      </button>
    </ng-container>
    <ng-container *ngIf="tab.disabled">
      <span class="tab-link disabled" [title]="tab.disabledTooltip ?? ''">
        <span>{{ tab.title }}</span>
      </span>
    </ng-container>
  </li>
</ul>
